.blog-page-container {
    padding: 20px;
    max-width: 900px;
    margin: 0 auto;
  }
  
  .back-button {
    display: flex;
    align-items: center;
    background: none;
    border: none;
    font-size: 16px;
    color: #007bff;
    cursor: pointer;
  }
  
  .back-icon-container {
    margin-right: 8px;
  }
  
  .back-icon {
    width: 16px;
    height: 16px;
  }
  /* Layout container styles */
.page-layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Full height layout */
  }
  
  .blog-page-container {
    flex: 1 1;
    padding: 20px;
    width: 90%;
    max-width: 1500px;
    margin: 0 auto;
  }
  
  footer {
    background-color: #f1f1f1;
    padding: 20px;
    text-align: center;
  }
  
  .blog-preview-card {
    margin-top: 20px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  .blog-date-badge {
    font-size: 14px;
    color: #777;
  }
  
  .blog-title {
    font-size: 28px;
    font-weight: bold;
    margin-top: 10px;
  }
  
  .blog-excerpt {
    font-size: 18px;
    color: #333;
    margin-top: 10px;
  }
  
  .blog-divider {
    margin: 20px 0;
  }
  
  .author-info {
    margin-top: 15px;
  }
  
  .author-label {
    font-size: 14px;
    color: #555;
  }
  
  .author-name {
    font-size: 16px;
    font-weight: bold;
  }
  
  .blog-content-card {
    margin-top: 30px;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    word-wrap: break-word; /* Allows long words to break and wrap to the next line */
    white-space: normal;
  }
  
  .blog-text {
    font-size: 18px;
    line-height: 1.6;
    color: #333;
    white-space: pre-line; /* Preserves line breaks in the text content */
    overflow-wrap: break-word; 
  }
  