.services-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin: 20px;
  max-width: 1200px;
  width: 100%;
}
.lcontainer {
  display: flex;
  justify-content: center;
  padding-top: 20px;
}
.lservice {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f6f7f9;
  border: 1px solid #e1e4eb;
  border-radius: 30px;
  cursor: pointer;
  gap: 10px;
  max-width: 136px;
  padding: 3px 10px;
  height: 40px;
}
.features-list {
  height: 460px;
}
.ssv {
  font-family: Arial, sans-serif;
  background-color: white;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.service-card {
  display: flex;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px #00000045;
  padding: 20px;
  flex-direction: column;
  justify-content: space-around;
}
.website-button {
  height: 31px;
  margin-top: 7px;
  background: white;
  border-radius: 5px;
  padding: 0.4rem 0.7rem;
  font-size: 0.8rem;
  color: #333;
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}
/* .conx{
        padding-left: 10px;
   
      } */
.service-card h2 {
  font-size: 19px;
  margin-bottom: 10px;
  font-family: "Instrument Sans";
  font-family: Instrument Sans;
  font-size: 19px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
  font-optical-sizing: auto;
}

.service-card p {
  font-size: 14px;
  color: #666;
  margin-top: 20px;
  margin-bottom: 15px;
}

.service-card ul {
  gap: 5px;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
}

.service-card ul li {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #666;
  margin-bottom: 5px;
}

.service-card ul li i {
  color: #8bc34a;
  margin-right: 10px;
}

.service-card .image-placeholder {
  background-color: #e0e0e0;
  border-radius: 10px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.service-card .checkbox-container {
  display: flex;
  align-items: center;
}

.service-card .checkbox-container input {
  margin-right: 10px;
}

.service-card .support-container {
  align-items: center;
  align-items: flex;
  display: flex;
  /* top: 43px; */
  position: relative;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.service-card .support-container {
  display: flex;
  align-items: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  background: #d8dbe5;
  border-radius: 10px;
}
.support-icons2 {
  display: flex;
  gap: 12px;
  margin-top: 4px;
  margin-bottom: 8px;
}
.sss {
  height: 28px;
  width: 32px;
  padding-left: 7px;
  padding-top: 10px;
}
.ssx {
  height: 30px;
  width: 34px;
  padding-left: 6px;
  padding-top: 12px;
}
.sxx {
  height: 30px;
  width: 34px;
  padding-left: 6px;
  padding-top: 9px;
}
.service-card .support-container .support-icons i {
  margin-right: 5px;
  color: #ccc;
}

.service-card .support-container .support-text {
  background-color: #000;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
}

.card-span {
  grid-row: span 2;
}

.ssv h1 {
  text-align: center;
  margin-top: 1px;
  font-size: 35px;
  margin-bottom: 40px;
  font-weight: bold;
}
.txxt {
  padding: 50px;
}
.services-grid {
  display: grid;
  gap: 20px;
}

.dot {
  height: 40px;
  width: 40px;
  background-color: #fbf7f7;
  border-radius: 50%;
  display: inline-block;
  box-shadow: 0px 0px 9px 2px rgba(0, 0, 0, 0.5);
}
.ht {
  padding-top: 50px;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.toolbar {
  display: flex;
  background-color: #f5f5f5;
  border-radius: 4px;
  padding: 8px;
}
.tool {
  background: none;
  border: none;
  color: #666;
  font-size: 14px;
  margin-right: 12px;
  cursor: pointer;
}
.tool:last-child {
  margin-right: 0;
}
.card:hover {
  transform: translateY(-5px);
}
.card h2 {
  font-size: 20px;
  margin-bottom: 10px;
}
.card p {
  font-size: 16px;
  color: #666;
  margin-bottom: 15px;
}
.card ul {
  list-style-type: none;
  padding-top: 80px;
}
.card li {
  font-size: 15px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  padding-top: 80px;
}
.checkmark {
  width: 14px;
  height: 14px;
  top: 206px;
  background: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 1.3125C5.87512 1.3125 4.7755 1.64607 3.8402 2.27102C2.90489 2.89597 2.17591 3.78423 1.74544 4.82349C1.31496 5.86274 1.20233 7.00631 1.42179 8.10958C1.64124 9.21284 2.18292 10.2263 2.97833 11.0217C3.77374 11.8171 4.78716 12.3588 5.89043 12.5782C6.99369 12.7977 8.13726 12.685 9.17651 12.2546C10.2158 11.8241 11.104 11.0951 11.729 10.1598C12.3539 9.2245 12.6875 8.12488 12.6875 7C12.6859 5.49207 12.0862 4.04636 11.0199 2.98009C9.95365 1.91382 8.50793 1.31409 7 1.3125ZM9.49703 5.99703L6.43453 9.05953C6.3939 9.10021 6.34565 9.13248 6.29254 9.1545C6.23943 9.17651 6.1825 9.18784 6.125 9.18784C6.06751 9.18784 6.01058 9.17651 5.95747 9.1545C5.90435 9.13248 5.8561 9.10021 5.81547 9.05953L4.50297 7.74703C4.42088 7.66494 4.37476 7.5536 4.37476 7.4375C4.37476 7.3214 4.42088 7.21006 4.50297 7.12797C4.58506 7.04588 4.69641 6.99976 4.8125 6.99976C4.9286 6.99976 5.03994 7.04588 5.12203 7.12797L6.125 8.13148L8.87797 5.37797C8.91862 5.33732 8.96688 5.30508 9.01999 5.28308C9.07309 5.26108 9.13002 5.24976 9.1875 5.24976C9.24499 5.24976 9.30191 5.26108 9.35502 5.28308C9.40813 5.30508 9.45639 5.33732 9.49703 5.37797C9.53768 5.41862 9.56993 5.46687 9.59192 5.51998C9.61392 5.57309 9.62525 5.63001 9.62525 5.6875C9.62525 5.74499 9.61392 5.80191 9.59192 5.85502C9.56993 5.90813 9.53768 5.95638 9.49703 5.99703Z' fill='%2395B500'/%3E%3C/svg%3E");
  background-size: cover;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.checkmar {
  width: 14px;
  height: 14px;
  top: 206px;

  background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 1.3125C5.87512 1.3125 4.7755 1.64607 3.8402 2.27102C2.90489 2.89597 2.17591 3.78423 1.74544 4.82349C1.31496 5.86274 1.20233 7.00631 1.42179 8.10958C1.64124 9.21284 2.18292 10.2263 2.97833 11.0217C3.77374 11.8171 4.78716 12.3588 5.89043 12.5782C6.99369 12.7977 8.13726 12.685 9.17651 12.2546C10.2158 11.8241 11.104 11.0951 11.729 10.1598C12.3539 9.2245 12.6875 8.12488 12.6875 7C12.6859 5.49207 12.0862 4.04636 11.0199 2.98009C9.95365 1.91382 8.50793 1.31409 7 1.3125ZM9.49703 5.99703L6.43453 9.05953C6.3939 9.10021 6.34565 9.13248 6.29254 9.1545C6.23943 9.17651 6.1825 9.18784 6.125 9.18784C6.06751 9.18784 6.01058 9.17651 5.95747 9.1545C5.90435 9.13248 5.8561 9.10021 5.81547 9.05953L4.50297 7.74703C4.42088 7.66494 4.37476 7.5536 4.37476 7.4375C4.37476 7.3214 4.42088 7.21006 4.50297 7.12797C4.58506 7.04588 4.69641 6.99976 4.8125 6.99976C4.9286 6.99976 5.03994 7.04588 5.12203 7.12797L6.125 8.13148L8.87797 5.37797C8.91862 5.33732 8.96688 5.30508 9.01999 5.28308C9.07309 5.26108 9.13002 5.24976 9.1875 5.24976C9.24499 5.24976 9.30191 5.26108 9.35502 5.28308C9.40813 5.30508 9.45639 5.33732 9.49703 5.37797C9.53768 5.41862 9.56993 5.46687 9.59192 5.51998C9.61392 5.57309 9.62525 5.63001 9.62525 5.6875C9.62525 5.74499 9.61392 5.80191 9.59192 5.85502C9.56993 5.90813 9.53768 5.95638 9.49703 5.99703Z' fill='%23C9CDD2'/%3E%3C/svg%3E");
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  border: none;
  width: 14px;
}

.item {
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 5px;
  background-color: #f2f2f2;
  color: #777;
}

.item.completed {
  background-color: #ebfaea;
  color: #848a84;
}

.print-media {
  box-shadow: 2px solid #77229a;
}
.service-card:nth-child(7) {
  justify-content: space-between;
}

.service-card:nth-child(3) {
  border-bottom: 2px solid #e6e606de; /* Different border color */
  box-shadow: 0 4px 10px rgba(162, 231, 215, 0.4); /* Different shadow */
}
.features-list {
  gap: 5px;
  padding-top: 30px;
}

.service-card:nth-child(5) {
  border-bottom: 2px solid #4d135ade; /* Different border color */
  box-shadow: 0 4px 10px#4d135ade; /* Different shadow */
}
.shadow-card {
  width: 172px;
  height: 275px;
  border-radius: 20px;
  background-color: #333;
  box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.2);
  position: relative;
  top: -134px;
  left: 10%;
  gap: 0px;
  /* background-image: url("C:\Users\HP\Downloads\soocily-master (1)\soocily-master\public\img\image-2.jpeg"); */
  background-position: center;
  background-size: cover;

}

.shadow-background {
  position: relative;
  left: 145px;
  width: 170px;
  height: 275px;
  border-radius: 20px;
  background-color: #d3d3d3;
  top: 98px;
  left: 41%;
  gap: 0px;
  /* background-image: url("C:\Users\HP\Downloads\soocily-master (1)\soocily-master\public\img\image-1.jpeg"); */
  background-position: center;
  background-size: cover;
}
.editor-interface {
  /* background-color: #f0f0f0;
    border-radius: 5px;
    padding: 10px; */
  /* margin-top: 67px; */
}
.editor-buttons {
  display: flex;
  gap: 5px;
  margin-bottom: 5px;
}
.editor-button {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 3px;
  padding: 2px 5px;
  font-size: 12px;
}
.ecom-button {
  background-color: #333;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px;
  width: 300px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 20px;
}
.xtx {
  color: white;
  text-decoration: none;
}
.xt {
  display: flex;
  gap: 48px;
  flex-direction: column;
  justify-content: space-between;
}
.TT {
  display: flex;
  justify-content: space-between;
  height: 26px;
}

.TT img {
  height: 17px;
  width: 22px;
}
@media (max-width: 1100px) {
  .services-container {
    grid-template-columns: repeat(2, 1fr);
    width: 92%;
    justify-content: center;
  }
  .service-card {
    padding: 15px;
  }
}

@media (max-width: 768px) {
  .services-container {
    grid-template-columns: 1fr;
    width: 88%;
    justify-content: center;
  }
  .service-card {
    padding: 15px;
  }
  .service-card p {
    font-size: 14px;
    color: #666;
    /* margin: 24px; */
    margin-bottom: 30px;
    margin-top: 20px;
  }
  .shadow-card {
    width: 172px;
    height: 275px;
    border-radius: 20px;
    background-color: #333;
    box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.2);
    position: relative;
    top: -134px;
    left: 27%;
    gap: 0px;
  }
  .service-card .support-container {
    align-items: stretch;
    align-items: flex;
    display: flex;
    top: 4px;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    max-width:370px;
  }
  .ssv h1 {
    text-align: center;
    margin-top: 50px;
    font-size: 35px;
    font-weight: bold;
    padding: 10px;
  }
}

@media (max-width: 480px) {
  .services-container {
    width: 90%;
    padding: 0 10px;
  }
  .service-card {
    padding: 10px;
  }
  .service-card p {
    font-size: 14px;
    color: #666;
    /* margin: 24px; */
    margin-bottom: 30px;
    margin-top: 20px;
  }
  .service-card .support-container {
    align-items: center;
    align-items: flex;
    display: flex;
    top: 0px;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
}

@media (max-width: 400px) {
  .ecom-button {
    background-color: #333;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px;
    width: 280px;
    text-align: center;
    margin-top: 10px;
  }
  .shadow-background {
    position: relative;
    left: 145px;
    width: 170px;
    height: 275px;
    border-radius: 20px;
    background-color: #d3d3d3;
    top: 98px;
    left: 28%;
    gap: 0px;
  }
  .shadow-card {
    width: 172px;
    height: 275px;
    border-radius: 20px;
    background-color: #333;
    box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.2);
    position: relative;
    top: -134px;
    left: 5%;
    gap: 0px;
  }
}
/*  */
.service-card:nth-child(1),
.service-card:nth-child(2),
.service-card:nth-child(4),
.service-card:nth-child(5),
.service-card:nth-child(6),
.service-card:nth-child(7),
.service-card:nth-child(8) {
  height: 320px;
}
@media (max-width: 768px) {
  .service-card:nth-child(1),
  .service-card:nth-child(2),
  .service-card:nth-child(5),
  .service-card:nth-child(8) {
    height: 300px;
  }
  .service-card:nth-child(7){
    height: 340px;
  }
  .service-card:nth-child(6),
  .service-card:nth-child(4){
    height: 320px;
  }
  .service-card:nth-child(3) {
    height: 500px;
    }
    .features-list{
        margin-top: -113px;
    }
    .editor-interface {
        margin-top: 16px;
    }
}
/* @media (max-width:480px){
    .service-card .support-container {
        top:-22px;
    }
} */

.x1{
  margin-top: -15px;
}
.x2{
  margin-top: -16px;
}

@media (max-width:590px) and (min-width:480px){
  .service-card .support-container {
      top:3px;
  }
}
@media (max-width:407px){
  .service-card .support-container {
      top:-5px;
  }
}
@media (max-width: 460px) {
  .editor-interface {
      margin-top: 7px;
  }
}
@media (max-width:768px) and (min-width:480px){
  .support-icons{
    margin-left: 34px;
  }
}