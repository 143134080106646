.artbox-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    margin-top: 110px;
}

.artbox-about-section {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 2rem;
}
.ra-logox img{
    height: 96%;
    width: 22px;
}
.artbox-about-content {
    flex: 1 1 500px;
}

.artbox-about-image {
    flex: 1 1 400px;
}
.ra-logo-text{
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}
.artbox-subtitle {
    font-size: 1.25rem;
    color: #4b5563;
    margin-top: 2rem;
}

.artbox-title {
    font-size: 54px;
    font-weight: 600;
    margin-bottom: 1rem;
}

.artbox-description {
    font-size: 16px;
    line-height: 1.7rem;
    color: #4b5563;
    margin-bottom: 1.5rem;
}

.artbox-cta-buttons {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
}

.artbox-btn {
    display: inline-flex;
    align-items: center;
    padding: 0.75rem 1.5rem;
    border-radius: 100px;
    font-weight: 600;
    text-decoration: none;
    transition: background-color 0.3s;
    font-size: 16px;
    height: 52px;
}

.artbox-btn-primary {
    background: linear-gradient(180deg, #802C90 0%, #BE7A4F 100%);
    color: white;
    transition: transform 0.3s ease; 

    /* background: rgba(230, 233, 238, 1);
    color: #4b5563;
    border: 1px solid #d1d5db;
    transition: transform 0.3s ease; */
}


.artbox-btn-primary:hover {  
    transform: translateY(-2px);
}
.artbox-btn-primary:hover {
    background: linear-gradient(180deg, #802C90 0%, #BE7A4F 100%);
    color: white;
    transform: translateY(-2px);
    }

.artbox-btn-secondary {
    background: rgba(230, 233, 238, 1);
    color: #4b5563;
    border: 1px solid #d1d5db;
    transition: transform 0.3s ease;
   
}

.artbox-btn-secondary:hover {
    background: linear-gradient(180deg, #802C90 0%, #BE7A4F 100%);
    color: white;
    transform: translateY(-2px);
    }

.artbox-btn svg {
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
    rotate:-30deg;
}

img {
    max-width: 100%;
    height: auto;
    border-radius: .5rem;
}

@media (max-width: 768px) {
    .artbox-container {
        max-width: 1200px;
        margin: 0 auto;
        padding: 2rem;
        margin-top: 10px;
    }
    .artbox-about-section {
        flex-direction: column;
    }

    .artbox-title {
        font-size: 2rem;
        text-align: left;
        }
    /* .artbox-about-image{
        margin-top: -10px;
    } */
    .artbox-about-content {
        flex: 1 1 415px;
    }
   
}
@media (width:767px){
    .artbox-about-image{
        margin-top: -115px;
    }
}
@media (max-width:480px){
.artbox-cta-buttons {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: center;
}
.artbox-btn {
    display: inline-flex;
    align-items: center;
    padding: 0.75rem 1.5rem;
    border-radius: 100px;
    font-weight: 600;
    text-decoration: none;
    transition: background-color 0.3s;
    font-size: 12px;
    height: 47px;
}
.artbox-about-section {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 4rem;
}}