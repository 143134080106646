/* .back-button {
    display: flex;
    align-items: center;
    background: none;
    border: none;
    font-size: 16px;
    color: #007bff;
    cursor: pointer;
  }
   
  .back-icon-container {
    margin-right: 8px;
  }
   
  .back-icon {
    width: 16px;
    height: 16px;
  } */
.policy-container {
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    max-width: 1200px;
    margin: 0 auto;
    /* background-color: #b5b0b0; */
    border-radius: 8px;
    
  }
  .policy-container h1{
    font-size: 26px;
    font-weight: bold;
    color: #000000;
    text-align: center;
   
  }
  .policy-container h2 {
    color: #000000;
  }
  .policy-container a {
    color: blue;
    text-decoration: none;
  }
  .policy-container a:hover {
    text-decoration: underline;
  }
  .policy-container ul {
    margin: 10px 0;
    padding-left: 20px;
  }
 
  .policy-container ul li {
    margin-bottom: 8px;
  }
  hr {
    border: 0;
    border-top: 2px solid #ddd;
    margin: 20px 0; /* Adjust the spacing around the line */
    width: 100%;
  }
 
  /* Responsive Design */
  @media (max-width: 768px) {
    .policy-container {
      padding: 15px;
    }
 
    .policy-container h1 {
      font-size: 24px;
    }
 
    .policy-container h2 {
      font-size: 20px;
    }
 
    .policy-container p, li {
      font-size: 14px;
    }
  }
 
  @media (max-width: 480px) {
    .policy-container {
      padding: 10px;
    }
 
    .policy-container h1 {
      font-size: 20px;
    }
 
    .policy-container h2 {
      font-size: 18px;
    }
 
    .policy-container p, li {
      font-size: 12px;
    }
  }