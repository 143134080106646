
.case-studies-section {
  max-width: 1200px;
  margin: 2rem auto;
  padding: 0 1rem;
}

.header-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
}

.case-studies-button {
  display: flex;
  align-items: center;
  gap: 4px;
  background-color: #fff;
  border: 2px solid #e0e0e0;
  border-radius: 25px;
  padding: 10px 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 170px;
  height: 48px;
  justify-content: center;
}

.case-studies-button:hover {
  box-shadow: 0 4px 8px rgba(156, 39, 176, 0.2);
}

.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-container img {
  width: 20px;
  height: 30px;
}

.button-text {
  font-size: 12px;
  color: #333;
  font-weight: 500;
}

.section-heading {
  text-align: center;
  margin-bottom: 3rem;
  padding-top: 8px;
}

.case-studies-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.case-card {
  border-radius: 1rem;
  overflow: hidden;
  border: 1px solid #DA08FF;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
 
}

.case-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.category-tag-container {
  padding-left: 16px;
  margin: 6px;
}

.category-tag {
  display: inline-block;
  text-align: left;
  padding: 0.5rem 6rem 0.5rem 0.5rem;
  border-radius: 0.4rem;
  background: linear-gradient(90deg, #76209C 0%, #DEA12B 100%);
  color: white;
  font-size: 0.875rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.case-image-container {
  margin: 20px;
}

.case-image {
  width: 100%;
  height: 200px;
  background-color: #e5e7eb;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6b7280;
  border-radius: 10px;
  margin-top: -15px;
}

.case-content {
  padding: 1.5rem;
}

.case-title {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: #1f2937;
}

.case-description {
  color: #4b5563;
  margin-bottom: 1.5rem;
  line-height: 1.5;
}

.read-more-container {
  text-align: right;
}

.read-more {
  display: inline-block;
  /* color: #6366f1; */
  color: rgba(0, 0, 0, 1);

  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
}

.read-more:hover {
  color: #4f46e5;
}

@media (max-width: 768px) {
  .case-studies-grid {
    grid-template-columns: 1fr;
  }
}

.comparison-container {
  max-width: 1200px;
  margin: 140px auto;
  font-family: Arial, sans-serif;
  position: relative;
}

.comparison-badge {
  position: absolute;
  top: -41px;
  left: 50%;
  border: 1px solid #e1e4eb;
  transform: translateX(-50%);
  background: rgb(245, 246, 250);
  padding: 8px 16px;
  border-radius: 20px;
  box-shadow: 0 2px 4px rgba(61, 14, 180, 0.1);
  display: flex;
  align-items: center;
  gap: 8px;
  height: 40px;
}

.comparison-badge span {
  color: #0d0a10;
  font-weight: 500;
}

.title {
  text-align: center;
  font-size: 36px;
  font-weight: 600;
  margin: 40px 0;
}

.comparison-grid {
  display: flex;
  gap: 20px;
  padding: 20px;
  position: relative;
}

.comparison-side {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: white;
  border-radius: 24px;
  padding: 15px;
}

.box-other-agencies {
  background: linear-gradient(to right, rgb(227, 227, 230), #bb96f1ec);
}

.box-soocily {
  background: linear-gradient(to left, rgb(221, 221, 224), #bb96f1ec);
}

.vs-container {
  position: absolute;
  top: 12%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.middle-circle {
  position: absolute;
  width: 60px;
  height: 60px;
  background-color: #ffffff;
  border-radius: 50%;
  box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.6);
}
.fa-solid{
  color: #400ed8;
}
.inner-circle {
  position: relative;
  width: 50px;
  height: 50px;
  background-color: #783ec9;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inner-circle span {
  color: white;
  font-weight: bold;
  font-size: 16px;
}

.dimensions {
  background: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  color: #666;
}

.side-title {
  font-size: 24px;
    font-weight: 600;
    margin-top: 18px;
    margin-bottom: 28px;
    margin-left: 160px;
}

.comparison-list {
  flex-grow: 1;
  background: white;
  border-radius: 16px;
  padding: 4px;
}

.comparison-item {
  display: flex;
  align-items: center;
  padding: 16px;
  margin: 8px;
  background: white;
  border-radius: 12px;
  font-size: 15px;
  color: #333;
}

/* Left side X marks */
.negative-item::before {
  width: 20px;
  height: 20px;
  background-color: #edeff3;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  content: "×";
  color: rgb(15, 14, 14);
  font-size: 15px;
}

/* Right side check marks */
.positive-item::before {
  width: 20px;
  height: 20px;
  background: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='20' height='20' rx='10' fill='%2307C51A'/%3E%3Cg clip-path='url(%23clip0_23_1338)'%3E%3Cg clip-path='url(%23clip1_23_1338)'%3E%3Cpath d='M13.958 7.29199L8.51599 12.708L6.04199 10.246' stroke='white' stroke-width='1.667' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_23_1338'%3E%3Crect width='10' height='10' fill='white' transform='translate(5 5)'/%3E%3C/clipPath%3E%3CclipPath id='clip1_23_1338'%3E%3Crect width='10' height='10' fill='white' transform='translate(5 5)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E") center / cover no-repeat;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  content: "";
}


.soocily-logo {
  
    height: 124px;
    margin-bottom: -19px;
    width: 140px;
    margin-top: -30px;
    margin-left: 201px;

}
@media (max-width: 768px) {
  .comparison-container {
    margin: 60px auto;
    padding: 10px;
  }

  .title {
    font-size: 24px;
    margin: 20px 0;
  }

  .comparison-grid {
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    position: relative;
  }

  .vs-container {
    position: absolute;
    top: 48%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60px;
    height: 60px;
  }

  .middle-circle {
    width: 40px;
    height: 40px;
  }

  .inner-circle {
    width: 30px;
    height: 30px;
    font-size: 10px;
  }

  .side-title {
    font-size: 20px;
    margin-left: 0;
    text-align: center;
    margin-bottom: 20px;
  }

  .comparison-item {
    font-size: 14px;
    padding: 10px;
    margin: 4px;
  }

  .soocily-logo {
    height: 80px;
    width: 100px;
    margin: 0 auto 10px;
  }
  .comparison-badge {
    position: absolute;
    top: -21px;
    left: 50%;
    transform: translateX(-50%);
    background: rgb(245, 246, 250);
    padding: 8px 16px;
    border-radius: 20px;
    box-shadow: 0 2px 4px rgba(61, 14, 180, 0.1);
    display: flex;
    align-items: center;
    gap: 8px;
  }
}
@media (max-width: 420px) {

  .vs-container {
    position: absolute;
    top: 46%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60px;
    height: 60px;
  }
}
@media (max-width: 900px) and (min-width: 770px){
  .side-title{
    margin-left: 80px;
  }
  .soocily-logo{
    margin-left: 114px;
  }
}