* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Arial, sans-serif;
}
/* model css */
/* Modal Overlay */
.modal-overlay {
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background: rgba(0, 0, 0, 0.5); /* 50% transparent */
display: flex;
justify-content: center;
align-items: center;
z-index: 1000;
}

/* Modal Container */
.modal-content {
background: white;
padding: 2rem;
border-radius: 10px;
width: 90%;
max-width: 400px;
max-height: 80vh;
overflow-y: auto;
box-shadow: 0 8px 16px rgb(89 29 29 / 76%);
position: relative;
text-align: center;
animation: fadeIn 0.3s ease;
}

/* Close Button */
.close-btn {
position: absolute;
top: 1rem;
right: 1rem;
background: transparent;
border: none;
font-size: 1.5rem;
color: #888;
cursor: pointer;
}

.close-btn:hover {
color: #555;
}

/* Modal Title */
.modal-title {
font-size: 1.5rem;
font-weight: bold;
color: #333;
margin-bottom: 1.2rem;
}

/* Form Fields */
.form-group {
display: flex;
flex-direction: column;
gap: 0.5rem;
margin-bottom: 1rem;
}

.form-group label {
font-size: 0.875rem;
color: #0d0d0d;
text-align: left;
}

.form-group input,
.form-group textarea {
width: 100%;
  padding: 0.5rem 0;
  border: none;
  border-bottom: 1px solid #131212;
  background: transparent;
  font-size: 0.95rem;
  outline: none;
  transition: border-color 0.3s ease;
}


.form-group input:focus,
.form-group textarea:focus {
border-color: #007bff;
}

/* Placeholder Styling */
.form-group input::placeholder,
.form-group textarea::placeholder {
color: #aaa;
}

/* Textarea */
.form-group textarea {
resize: none;
height: 60px;
}

/* Submit Button */
.button-group {
margin-top: 1.5rem;
}

.submit-btn {
background-color: #007bff;
color: #fff;
padding: 0.8rem 1rem;
font-size: 1rem;
border: none;
border-radius: 5px;
cursor: pointer;
transition: background-color 0.3s ease;
}

.submit-btn:hover {
background-color: #0056b3;
}

/* Fade-in Animation */
@keyframes fadeIn {
from {
  opacity: 0;
  transform: translateY(-20px);
}
to {
  opacity: 1;
  transform: translateY(0);
}
}

/* Responsive Design */
@media (max-width: 480px) {
.modal-content {
  width: 90%;
  padding: 1.5rem;
}
}


/* ending model css */
body {
  display: block;
}

.hb-main {
  background: linear-gradient(180deg, #73199B 0%, #D18B08 100%);

  height: 900px;
  color: white;
  line-height: 1.6;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hb-hero-section {
  text-align: center;
  top: -121px;
  padding: 2.5rem 1rem 2rem;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  position: relative;
}

.hb-nav {
  display: flex;
  /* height: 80px; */
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  /* backdrop-filter: blur(10px); */
  border-radius: 50px;
  padding: 0.5rem 1.5rem;
  margin-bottom: 35px;
  width: 100%;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 1;
}

/* Apply transition only when .transition class is present */

/* .hb-nav-placeholder {
 
  transition: height 0.3s ease-in-out;
} */
.hb-nav.sticky + .hb-nav-placeholder {
  height: 70px; 
}

.hb-nav.sticky {
transition: width 0.3s ease-in-out;
position: fixed;
top: 10px; /* Adjust based on your design */
left: 50%;
transform: translateX(-50%); /* Center the navbar horizontally */
width: 100%; /* Full width or adjust as necessary */
max-width: 1000px; /* Optional: Set a max width for the navbar */
z-index: 1000; /* Ensures it stays above other content */
box-shadow: 0px 2px 10px 0px rgba(132, 43, 139, 1); /* Optional shadow for visual separation */
}
.hb-logo img {
  height: 35px;
  width: auto;
  margin-top: 8px;
}

.hb-nav-links {
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  list-style: none;
  margin-right: 10px;
}

.hb-nav-links a {
  color: black;
  text-decoration: none;
  font-size: 0.95rem;
  font-family: Instrument Sans;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: center;
  
}
.hb-nav-links a:hover {
  transform: translateY(-2px);
}

.hb-contact-btn {
  background: linear-gradient(90deg, #731c9e 0%, #c38049 100%);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 25px;
  transition: transform 0.3s ease;
  margin-left: 70px;
  width: 100px;
  height: 50px;
  margin-right: -22px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.hb-contact-btn{
color: white;
}
.hb-contact-btn:hover {
  transform: translateY(-2px);
}



.hb-since {
  background: white;
  padding: 0.5rem 1.5rem;
  border-radius: 28px;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
  color: black;
  font-weight: normal;
}
.net {
  justify-content: center;
}

.net img {
  display: flex;
  display: inline-flex;
}

.hb-point img {
  margin-top: 10px;
  height: 35px;
  width: 40px;
}

.hb-since::before {
  content: "•";
  color: #4caf50;
}

.hb-title {
  /* font-size: clamp(2rem, 5vw, 3.5rem); */
 
  margin-bottom: 1rem;
  /* font-family: Inter; */
  font-size: 56.84px;
  font-weight: 500;

  text-align: center;
  line-height: 1.2;
}

.hb-stats {
  font-family: Instrument Sans;
  font-size: 18px;
  /* font-weight: 700; */
  line-height: 27px;
  text-align: center;
  /* font-size: clamp(0.9rem, 2vw, 1rem); */
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 1.5rem;
}

.hb-net {
  top: -3px;
  z-index: 0;
  position: relative;
}

.hb-atlft {
  left: 0%;
  top: 11%;
  position: absolute;
}

.hb-atright {
  right: 0%;
  top: 11%;
  position: absolute;
}

.hb-top {
  position: absolute;
  top: -325px;
  left: 29%;
  z-index: 0;

}

.hb-top img {
  height: 456px;
  width: 476px;
}

.hb-call {
  font-family: Instrument Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  z-index: 1;
}

.hb-actions {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
  margin-bottom: -1.5rem;
  /* backdrop-filter: blur(5px); */
  display: inline-flex;
  z-index: 2;
  margin-top: 0px;
  top: 79.5%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hb-cta-button {
  background: rgba(255, 255, 255, 0.9);
  color: #6b2c94;
  padding: 0.8rem 1.5rem;
  border-radius: 30px;
  text-decoration: none;
  display: inline-block;
  font-weight: 600;
  transition: transform 0.3s ease;
}

.hb.cta-button:hover {
  transform: translateY(-2px);
}

.hb-a {
  z-index: -1;
  display: flex;
}

.hb-igl {
  margin-right: -4px;
  margin-top: 8px;
}

.hb-igr {
  margin-left: -4px;
  margin-top: 8px;
}

.hb-customers-badge {
  background-color: #c5a0b4;
  display: inline-flex;
  padding: 0.5rem 1.5rem;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  border: 1px solid white;
}

.hb-customers-badge span {
  color: black;
}

.hb-avatar-group {
  display: flex;
  align-items: center;
}

.hb-avatar {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid white;
  margin-right: -8px;
}


.hb-avatar img {
height: 21px;
/* margin-left: 0px; */
width: 21px;
}

.hb-stats-container {
  position: absolute;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 1rem;
  margin-top: 1rem;
  padding: 1rem;
  padding-top: 3rem;
  border: 1px solid white;
  border-radius: 32px;
  background: rgba(255, 255, 255, 0.05);
  max-width: 1180px;
  width: 100%;
  height: 243px;
  z-index: 1;
  margin-left: auto;
  margin-right: auto;
  top: 104.3%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hb-actions a {
  z-index: 1;
}

.hb-stat-card {
  background: linear-gradient(180deg, #e9dfdd 0%, #e1ccd5 100%);
  border-radius: 20px;
  /* padding: 1.5rem; */
  transition: transform 0.3s ease;
  color: black;
  text-align: center;
}

.hb-stat-card:hover {
  transform: translateY(-5px);
}

.hb-stat-number {
  color: black;
  font-size: 50px;
  margin-top: 1.5rem;
}

.hb-stat-label {
  color: #656565;
  font-weight: 600;
  font-size: 16px;
}

/* Mobile Menu Button */
.menu-toggle {
  display: none;
  flex-direction: column;
  gap: 4px;
  padding: 1rem;
  cursor: pointer;
}

.menu-toggle span {
  width: 25px;
  height: 2px;
  background-color: black;
  transition: 0.3s;
}



@media (max-width: 1100px) {
  .hb-nav.sticky {width: 96%;}
  .hb-stats-container {
    max-width: 800px;
  }
  .hb-atlft {
    display: none;
  }
  .hb-atright {
    display: none;
  }

  .hb-actions {
    top: 79.5%;
  }

  .hb-atlft {
    top: 8.3%;
  }

  .hb-atright {
    top: 8.3%;
  }
  .hb-net {
    max-width: 1000px;
    width: 100%;
  }
}

/* Responsive adjustments */
@media (max-width: 868px) {
  .hb-actions {
    position: relative;
    top: 58px;
    left: 180px;
  }

  .hb-hero-section {
    top: -20px;
  }

  .hb-stats-container {
    position: relative;
    top: 87px;
}

  .hb-client-logos,
  .certification-logos {
    gap: 20px;
  }

  .client-logo {
    height: 30px;
  }

  .certification-logo {
    height: 40px;
  }
  .hb-nav-links {
    gap: 1rem;
  }

  .hb-net {
    display: none;
  }

  .hb-atlft {
    display: none;
  }

  .hb-atright {
    display: none;
  }

  .hb-top {
    display: none;
  }
}

/* Updated media queries for better mobile responsiveness */
@media (max-width: 768px) {
  
  .hb-hero-section {
    padding: 4rem 1rem;
    top: -175px;
  }
  .hb-stat-number {
    color: black;
    font-size: 2rem;
    margin-top: 0.5rem;
}
.hb-main{
height: 1050px;
}
  .menu-toggle {
    display: flex;
  }

  .hb-nav-links {
    display: none;
    width: 100%;
    flex-direction: column;
    position: absolute;
    top: 80px;
    left: 0;
    background: white;
    padding: 1rem;
    border-radius: 0 0 20px 20px;
    z-index: 2;
  }

  .hb-nav-links.active {
    display: flex;
  }

  .hb-nav {
    flex-wrap: wrap;
    position: relative;
  }

  .hb-contact-btn {
    margin-top: 1rem;
    margin-right: 0px;
  }

  .hb-nav-links a {
    color: black;
    text-decoration: none;
    font-size: 0.95rem;
    margin-left: 0%;
  }

  .hb-part2 {
    position: relative;
    top: 100px;
  }

  .hb-customers-badge {
    gap: 1rem;
  }

  .hb-net {
    display: none;
  }

  .hb-actions {
    position: relative;
    top: 5px;
    /* left: 165px; */
    z-index: 2;
  }

  .hb-atlft {
    display: none;
  }

  .hb-atright {
    display: none;
  }

  .hb-stats-container {
    position: relative;
    top: 37px;
}
.hb-stat-card {
 
  padding: 1.5rem;
}

  .hb-top {
    display: none;
  }
  .hb-stats-container{
  position: absolute;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(169px, 1fr));
  gap: 1rem;
  margin-top: 1rem;
  padding: 1rem;
  padding-top: 2rem;
  border: 1px solid white;
  border-radius: 32px;
  background: rgba(255, 255, 255, 0.05);
  max-width: 1180px;
  width: 100%;
  height: 348px;
  z-index: 1;
  margin-left: auto;
  margin-right: auto;
  top: 165.3%;
  left: 50%;
  transform: translate(-50%, -50%);
  }
}

@media (max-width: 360px) {
  .hb-hero-section {
    top: 10px;
  }

  .net img {
    display: none;
  }

  .hb-point {
    display: none;
  }

  .hb-cta-button {
    display: none;
  }

  .hb-stats-container {
    position: relative;
    top: 214px;
    height: 624px;
  }
 

  .hb-actions {
    position: relative;
    top: -61px;
    z-index: 2;
  }

  .hb-nav-links {
    z-index: 3;
  }

  .hb-igr,
  .hb-igl {
    display: none;
  }

  .hb-a {
    
      position: relative;
      left: -37px;
      top: 11px;
  }

  .hb-main {
           height: 1285px;
  }
}

@media only screen and (min-width: 361px) and (max-width: 380px) {
  .hb-hero-section {
    padding: 3rem 0.5rem;
    top: -15%;
  }

  .hb-part2 {
    position: relative;
    top: 0px;
  }

  .hb-title {
    font-size: 1.8rem;
  }

  .hb-nav {
    width: 336px;
    padding: 0.4rem 0.8rem;
    z-index: 2;
  }

  .hb-logo img {
    height: 35px;
  }

  .hb-nav-links a {
    font-size: 0.75rem;
    padding: 0.3rem 0.6rem;
    margin-left: 0%;
  }

  .hb-contact-btn {
    padding: 0.3rem 0.8rem;
  }

  .hb-stats-container {
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    gap: 0.8rem;
    margin-top: -89px;
    z-index: 0;
  }

  .hb-stat-number {
    font-size: 1.5rem;
  }

  .hb-stat-label {
    font-size: 0.75rem;
  }

  .hb-customers-badge {
    gap: 11px;
    padding: 0.4rem 0.5rem;
    background-color: rgb(206, 156, 167);
  }

  .hb-stats {
    font-size: 0.95rem;
    padding: 0 0.5rem;
  }

  .net {
    display: flex;
    margin-left: 10px;
    gap: 42px;
  }

  .hb-point img {
    height: 43px;
    width: 43px;
    margin-left: -10px;
  }

  .hb-net {
    display: none;
  }

  .hb-actions {
    margin-top: 155px;
    top: 58%;
    left: 39%;
    z-index: 1;
  }

  .hb-a {
    max-width: 363px;
    width: 100%;
    left: 10px;
  }

  .hb-atlft,
  .hb-atright,
  .hb-top {
    display: none;
  }

  .hb-call {
    font-size: 12px;
    z-index: 1;
  }
}

@media only screen and (max-width: 400px) and (min-width: 380px) {
  .hb-hero-section {
    padding: 3rem 0.5rem;
    top: -15%;
  }

  .hb-part2 {
    position: relative;
    top: 0px;
  }

  .hb-title {
    font-size: 1.8rem;
  }

  .hb-nav {
    width: 336px;
    padding: 0.4rem 0.8rem;
  }

  .hb-logo img {
    height: 35px;
  }

  .hb-nav-links a {
    font-size: 0.75rem;
    padding: 0.3rem 0.6rem;
    margin-left: 0%;
  }

  .hb-contact-btn {
    padding: 0.3rem 0.8rem;
  }

  .hb-stats-container {
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    gap: 0.8rem;
    margin-top: -131px;
    z-index: 0;
  }

  .hb-stat-number {
    font-size: 1.5rem;
  }

  .hb-stat-label {
    font-size: 0.75rem;
  }

  .hb-customers-badge {
    gap: 11px;
    padding: 0.4rem 0.5rem;
    background-color: rgb(206, 156, 167);
  }

  .hb-stats {
    font-size: 0.95rem;
    padding: 0 0.5rem;
  }

  .net {
    display: flex;
    margin-left: 10px;
    gap: 42px;
  }

  .hb-point img {
    height: 43px;
    width: 43px;
    margin-left: -10px;
  }

  .hb-net {
    display: none;
  }

  .hb-actions {
    margin-top: 220px;
    top: 58%;
    left: 37%;
    z-index: 1;
  }

  .hb-a {
    max-width: 363px;
    width: 100%;
    left: 10px;
  }

  .hb-atlft,
  .hb-atright,
  .hb-top {
    display: none;
  }

  .hb-call {
    font-size: 12px;
    z-index: 1;
  }
}

@media only screen and (max-width: 420px) and (min-width: 401px) {
  .hb-hero-section {
    padding: 3rem 0.5rem;
    top: -18%;
  }

  .hb-part2 {
    position: relative;
    top: 0px;
  }

  .hb-title {
    font-size: 1.8rem;
  }

  .hb-nav {
    width: 385px;
    padding: 0.4rem 0.8rem;
    z-index: 2;
  }

  .hb-logo img {
    height: 35px;
  }

  .hb-nav-links a {
    font-size: 0.75rem;
    padding: 0.3rem 0.6rem;
    margin-left: 0%;
  }

  .hb-contact-btn {
    padding: 0.3rem 0.8rem;
  }

  .hb-stats-container {
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    gap: 0.8rem;
    margin-top: -33px;
    z-index: 0;
  }

  .hb-stat-number {
    font-size: 1.5rem;
  }

  .hb-stat-label {
    font-size: 0.75rem;
  }

  .hb-customers-badge {
    gap: 11px;
    padding: 0.4rem 0.5rem;
    background-color: rgb(206, 156, 167);
  }

  .hb-stats {
    font-size: 1.1rem;
    padding: 0 0.5rem;
  }

  .net {
    display: flex;
    margin-left: 20px;
    gap: 42px;
  }

  .hb-point img {
    height: 43px;
    width: 43px;
    margin-left: -10px;
  }

  .hb-net {
    display: none;
  }

  .hb-actions {
    margin-top: 70px;
    top: 108%;
    left: 35%;
    z-index: 1;
  }
  .hb-main{
    height: 960px;
  }

  .hb-a {
    max-width: 363px;
    width: 100%;
    left: 10px;
  }

  .hb-atlft,
  .hb-atright,
  .hb-top {
    display: none;
  }

  .hb-call {
    font-size: 12px;
    z-index: 1;
  }
}

@media only screen and (max-width: 440px) and (min-width: 421px) {
  .hb-hero-section {
    padding: 3rem 0.5rem;
    top: -15%;
  }

  .hb-part2 {
    position: relative;
    top: 0px;
  }

  .hb-title {
    font-size: 1.8rem;
  }

  .hb-nav {
    width: 336px;
    padding: 0.4rem 0.8rem;
  }

  .hb-logo img {
    height: 35px;
  }

  .hb-nav-links a {
    font-size: 0.75rem;
    padding: 0.3rem 0.6rem;
    margin-left: 0%;
  }

  .hb-contact-btn {
    padding: 0.3rem 0.8rem;
  }

  .hb-stats-container {
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    gap: 0.8rem;
    margin-top: -111px;
    z-index: 0;
  }

  .hb-stat-number {
    font-size: 1.5rem;
  }

  .hb-stat-label {
    font-size: 0.75rem;
  }

  .hb-customers-badge {
    gap: 11px;
    padding: 0.4rem 0.5rem;
    background-color: rgb(206, 156, 167);
  }

  .hb-stats {
    font-size: 1.1rem;
    padding: 0 0.5rem;
  }

  .net {
    display: flex;
    margin-left: 31px;
    gap: 42px;
  }

  .hb-point img {
    height: 43px;
    width: 43px;
    margin-left: -10px;
  }

  .hb-net {
    display: none;
  }

  .hb-actions {
    margin-top: 189px;
    top: 58%;
    left: 34%;
    z-index: 1;
  }

  .hb-a {
    max-width: 363px;
    width: 100%;
    left: 10px;
  }

  .hb-atlft,
  .hb-atright,
  .hb-top {
    display: none;
  }

  .hb-call {
    font-size: 12px;
    z-index: 1;
  }
}