
.terms-container {
    padding: 20px 190px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    max-width: 1200px;
    margin: 0 auto; /* Center the container horizontally */
    /* background-color: #b5b0b0; */
    
    border-radius: 8px; /* Rounded corners for the container */
  }
  
  .terms-title {
    font-size: 26px;
    font-weight: bold;
    color: #000000;
    text-align: center;
  }
  
  .terms-subtitle {
    font-size: 18px;
    font-weight: bold;
    color: #333;
    margin-top: 20px;
  }
  
  .terms-link {
    color: #1155cc;
    text-decoration: underline;
  }
  
  .terms-link ul {
    list-style-type: disc;
    /* margin-left: 40px; */
  }
  
  .terms-link li {
    margin-bottom: 10px;
  }
  
  .terms-link p {
    font-size: 16px;
    color: #333;
    margin-bottom: 15px;
  }
  
  .terms-link a {
    text-decoration: none;
  }
  
  .blog-content-card {
    word-wrap: break-word;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-top: 30px;
    padding: 20px;
    white-space: normal;
  }
  
  /* Horizontal line styles */
  hr {
    border: 0;
    border-top: 2px solid #ddd;
    margin: 20px 0; /* Adjust the spacing around the line */
    width: 100%;
  }
  
  /* Responsive Design */
  @media screen and (max-width: 768px) {
    .terms-container {
      padding: 15px;
    }
  
    .terms-title {
      font-size: 22px;
    }
  
    .terms-subtitle {
      font-size: 16px;
    }
  
    ul {
      margin-left: 30px;
    }
  }
  
  @media screen and (max-width: 480px) {
    .terms-title {
      font-size: 20px;
    }
  
    .terms-subtitle {
      font-size: 14px;
    }
  
    ul {
      margin-left: 20px;
    }
  }
  