.ss {
    font-family: Arial, sans-serif;

    margin: 0;
    padding: 20px;

    margin-top: 110px;
}

.p1-container {
    background: linear-gradient(180deg, #ece6f8 0%, rgba(228, 219, 246, 0) 100%);
    border-radius: 30px;
    padding: 60px;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.left-column {
    flex: 1;
    padding-left: 30px;
    padding-top: 40px;
}

.right-column {
    flex: 2;
    background-color: white;
    border-radius: 15px;

}

h1 {
    font-size: 45px;
    margin-bottom: 10px;
    padding-top: 20px;
}

.left-column p {
    font-size: 20px;
    color: #666;
    margin-bottom: 30px;
}

.book-call {
    display: inline-block;
    padding: 10px 20px;
    background: linear-gradient(180deg, #802C90 0%, #BE7A4F 100%);
    color: white;
 
    border: 1px solid #d1d5db;
    transition: transform 0.3s ease;
    text-decoration: none;
    border-radius: 50px;
    transition: background-color 0.3s;
    margin-top: 100px;
    width: 295px;
    /* height: 52px; */
    text-align: center;
    font-size: 16px;
    height: 52px;
    font-weight: 600;
    /* line-height: 50px; */
    transition: transform 0.3s ease;
}
.book-call svg {
    width: 1rem;
    height: 1.2rem;
    margin-left: 0.5rem;
    rotate:-30deg;
}

/* .book-call:hover {
    background-color: blue;
    color: white;
} */
.book-call:hover {

    background: linear-gradient(180deg, #802C90 0%, #BE7A4F 100%);
    color: white;
    transform: translateY(-2px);
}

.aj {
    width: 22px;
    color: black;
}

.aj:hover {
    color: #e0e0e0;
}

.right-column h2 {
    font-size: 24px;
    margin-top: 20px;
    margin-bottom: 20px;
   text-align: center;
}

.industry-list {
    display: flex;
    flex-wrap: wrap;
}

.industry-column {
    flex: 1;
    min-width: 200px;
    position: relative;

}

.industry-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding: 10px 0;
    border-bottom: 1px dotted #e0e0e0;
    padding-left: 38px;
    margin-top: 1px;
}



.icheckmark {
    width: 20px;
    height: 20px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    background: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='20' height='20' rx='10' fill='%2307C51A'/%3E%3Cg clip-path='url(%23clip0_23_1338)'%3E%3Cg clip-path='url(%23clip1_23_1338)'%3E%3Cpath d='M13.958 7.29199L8.51599 12.708L6.04199 10.246' stroke='white' stroke-width='1.667' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_23_1338'%3E%3Crect width='10' height='10' fill='white' transform='translate(5 5)'/%3E%3C/clipPath%3E%3CclipPath id='clip1_23_1338'%3E%3Crect width='10' height='10' fill='white' transform='translate(5 5)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E") center / cover no-repeat;
}



@media (min-width: 992px) {
    .p1-container {
        flex-direction: row;
    }

    .left-column {
        padding-right: 40px;
        padding-bottom: 0;
    }

    .industry-column:first-child::after {
        content: '';
        position: absolute;
        right: -20px;
        top: 0;
        bottom: 0;
        width: 1px;
        background-color: #e0e0e0;
    }

}

@media (min-width: 768px) and (max-width: 991px) {
    .left-column {
        flex: 1 1;
        padding-left: 0px;
        /* padding-top: 40px; */
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .book-call {
        margin-top: 20px;
        margin-bottom: 47px;
    }

    h1 {
        font-size: 35px;
        /* text-align: center; */
    }

    .left-column p {
        font-size: 18px;
    }

    .industry-list {
        flex-direction: row;
    }

    .industry-column {
        flex: 1;
    }
}

@media (max-width: 767px) {
    .ss {
        font-family: Arial, sans-serif;

        margin: 0;
        padding: 20px;

        margin-top: 10px;
    }

    .p1-container {
        padding: 30px;
    }

    .left-column {
        flex: 1 1;
        /* padding-left: 30px; */
        /* padding-top: 40px; */
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    h1 {
        font-size: 30px;
    }

    .left-column p {
        font-size: 16px;
        text-align: center;
    }

    .book-call {
        width: 192px;
        font-size: 20px;
        margin-bottom: 20px;
        /* margin-left: -14%; */
        margin-top: 20px;
        align-content: center;
    }

    .industry-list {
        flex-direction: column;
    }
}