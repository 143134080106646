.tf-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 90 20px;
}

.tf-header {
    display: flex;
    justify-content: center;
    padding: 20px 0;
}
.tf-testimonials-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1px;
    padding: 3px 10px;
    background-color: #F6F7F9;
    border: 1px solid #E1E4EB;
    border-radius: 30px;
    cursor: pointer;
    max-width: 180px;
    height: 40px;
}

.tf-testimonials-button img {
    width: 30px;
    height: 20px;
    object-fit: contain;}



.tf-slider-header {
    text-align: center;
    margin-bottom: 40px;
}

.tf-slider-header h2 {
    font-size: 2.5em;
    margin-top: 40px;
}

.tf-slider-container {
    overflow: hidden;
    width: 100%;
    position: relative;
    margin-top: -38px;
}

.tf-slide-track {
    display: flex;
    width: calc(300px * 14); 
    animation: scroll 50s linear infinite;
}

.tf-testimonial-card {
    width: 320px;
    background-color: #F6F7F9;
    border-radius: 15px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 0 15px;
    flex: none;
    position: relative;
}


.tf-testimonial-date {
    font-size: 0.9em;
    display: inline-block;
    color: #000;
    border: 1px solid #E1E4EB;
    padding: 7px;
    background-color: #fff;
    margin-bottom: 10px;
    border-radius: 20px;
}

.tf-testimonial-text {
    font-size: 1.1em;
    color: #333;
    margin-bottom: 20px;
}


.tf-user-info {
    border-top: 1px solid #E1E4EB;
    padding-top: 10px;
    display: flex;
    align-items: center;
}

.tf-user-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
    object-fit: cover;
}

.tf-user-name {
    font-size: 1em;
    color: #333;
    font-weight: bold;
}

.tf-user-position {
    font-size: 0.9em;
    color: #666;
}

.tf-twitter-logo {
    position: absolute;
    bottom: 28px;
    right: 22px;
    width: 40px;
    height: 40px;
    cursor: pointer;
}


@keyframes scroll {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(calc(-335px * 7)); 
    }
}

@media (max-width: 1024px) {
    .container {
        max-width: 90%;
    }

    .tf-slider-header h2 {
        font-size: 2em;
    }

    .tf-testimonial-card {
        width: 300px;
    }

    .tf-slide-track {
        width: calc(300px * 7);
    }

    @keyframes scroll {
        0% {
            transform: translateX(0);
        }
        100% {
            transform: translateX(calc(-300px * 7));
        }
    }
    
    .tf-testimonials-button{
        align-items: 100%;
    }
    .tf-faq h2 {
        font-size: 1.8em;

    }
}

@media (max-width: 768px) {
    .tf-slider-header h2 {
        font-size: 1.8em;
    }

    .tf-testimonial-card {
        width: 250px;
        padding: 15px;
    }

    .tf-slide-track {
        width: calc(250px * 7);
    }

    @keyframes scroll {
        0% {
            transform: translateX(0);
        }
        100% {
            transform: translateX(calc(-250px * 7));
        }
    }

    .tf-faq h2 {
        font-size: 1.6em;
    }
}

@media (max-width: 480px) {
    .tf-slider-header h2 {
        font-size: 1.5em;
    }

    .tf-testimonial-card {
        width: 299px;
    }

    .tf-slide-track {
        width: calc(200px * 7);
    }
    

    
    .tf-x{
        display: flex;
        gap: 10px;
        flex-direction: column;
    }
    .tf-social-icons {
        display: flex;
        gap: 10px;
        /* top: 20px; */
        justify-content: center;
    }

    @keyframes scroll {
        0% {
            transform: translateX(0);
        }
        100% {
            transform: translateX(calc(-200px * 7));
        }
    }

    .tf-faq h2 {
        font-size: 1.4em;
    }

    .tf-faq-item button {
        font-size: 1em;
    }
}
