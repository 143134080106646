.tf-faq {
    display: flex; /* Use flexbox for centering */
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items horizontally */
    padding: 40px 20px; /* Reduced padding for a smaller size */
    background-color: #fff;
    max-width: 600px; /* Set a max width for the FAQ section */
    margin: 0 auto; /* Center the section horizontally */
    border-radius: 10px; /* Optional: add rounded corners */
   /* Optional: add a subtle shadow */
}

.tf-faq-header {
    text-align: center;
    margin-bottom: 15px; /* Reduced margin */
}

.tf-faq-item {
    margin-bottom: 15px; /* Reduced margin */
    border-radius: 5px;
    width: 100%; /* Ensure items take full width */
}

.tf-faq-item button {
    width: 100%;
    background-color:#0f0f0f12;
    
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: #E1E4EB;
    padding: 10px;
    text-align: left;
    border: 1px solid;
    border-color: #dee2e7;
    font-size: 1em;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 10px;
}

.tf-faq-item button:hover {
    background-color: #e0e0e0;
}

.tf-faq-item button p {
    margin: 0;
    max-width: 90%;
}

.tf-plus-icon {
    width: 20px; /* Reduced size */
    height: 20px; /* Reduced size */
    background-color: black;
    color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    transition: transform 0.3s ease;
}

.tf-faq-item.active .tf-plus-icon {
    transform: rotate(45deg);
}

.tf-content {
    display: none;
    padding: 10px; /* Reduced padding */
    background-color: #fff;
    /* border-top: 1px solid #E1E4EB; */
}

.tf-faq-item.active .tf-content {
    display: block;
}

.tf-faq h2 {
    font-size: 1.5em; /* Adjusted font size for smaller screens */
    text-align: center;
    margin-bottom: 20px; /* Reduced margin */
}
.tf-faq-button {
    align-items: center;
    background-color: #f6f7f9;
    border: 1px solid #e1e4eb;
    border-radius: 20px;
    cursor: pointer;
    display: inline-flex;
    gap: 8px;
    justify-content: center;
    padding: 3px 20px;
    height: 37px;
}

.tf-faq-button img {
    height: 26px;
    object-fit: contain;
    width: 26px;
}



@media (max-width: 768px) {
    .tf-faq h2 {
        font-size: 1.4em; /* Adjusted font size for smaller screens */
    }

    .tf-faq-item button {
        font-size: 0.9em; /* Smaller font size */
    }
}

@media (max-width: 480px) {
    .tf-faq h2 {
        font-size: 1.2em; /* Further reduced font size */
    }

    .tf-faq-item button {
        font-size: 0.8em; /* Smaller font size */
    }

    .tf-plus-icon {
        width: 18px; /* Further reduced size */
        height: 18px; /* Further reduced size */
    }
}