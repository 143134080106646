.ra-blogs {
    display: flex;
    align-items: center;
    background-color: #f6f7f9;
    border: 1px solid #e1e4eb;
    border-radius: 30px;
    cursor: pointer;
    gap: 5px;
    height: 40px;
    max-width: 136px;
    padding: 3px 10px;
    justify-content: center;
}
.ra-blogs-logo {
    height: 100%;
    width: 100%;
}
.ra-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 10rem 1rem;
}
.ra-title {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 2rem;
}
.ra-articles-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 2rem;
}
.blog-card {
/*     
        height: 315px;
        width: 360px; */
        background: #eceef2;
    border: 1px solid #e1e4eb;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.tk{
    display: flex
;
    flex-direction: column;
    gap: 10px;
}
.blog-card-header {
    padding: 1rem;
    /* border-bottom: 1px solid #e1e4eb; */
}
.blog-date {
    font-size: 0.875rem;
    color: #999;
}
.blog-title {
    font-size: 1.25rem;
    font-weight: bold;
    margin-top: 1rem;
}
.blog-card-body {
    padding-left: 1rem;
    padding-right: 1rem;
    /* padding: 1rem; */
}
.blog-content {
    font-size: 1rem;
    color: #666;
}
.blog-card-footer {
    padding: 1rem;
    border-top: 1px solid #e1e4eb;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.blog-author {
    font-size: 0.875rem;
    color: #999;
}
/* .arrow-link {
    background: none;
    border: none;
    cursor: pointer;
    color: #333;
} */
.floating-button {
    background: linear-gradient(90deg, #731c9e, #c38049);
    border: none;
    border-radius: 14px;
    color: #fff;
    cursor: pointer;
    float: right;
    font-size: 16px;
    margin-top: 46px;
    padding: 15px 25px;
    transition: all .3s ease;
}