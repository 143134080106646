footer {
    background-color: #fff;
    padding: 0px;
    width: 100%;
    text-align: center;
    margin-top: auto;
    }

    .tf-logo {
    max-width: 180px;
    margin-bottom: -40px;
    }

   

.tf-footer-links {
   
    flex-wrap: wrap;
    /* justify-content: space-between; */
    gap: 10px;
    margin-bottom: 60px;
    }

    .tf-footer-links button {
     
            text-decoration: none;
            color: #262626;
            font-size: 1em;
            padding: 10px 15px;
            background-color: #dde3e9;
            border: none;
            border-radius: 17px;
            cursor: pointer;
            transition: background-color 0.3s ease;
            margin: 5px; /* Add margin to create spacing between buttons */
     
    }

    .tf-footer-links button:hover {
    background-color: #b4a2c3;
    }
    .tf-footer-links button a{
        text-decoration: none;
        color: black;
    }
    .tf-and-c{
        display: flex
;
    font-size: 0.7em;
    margin-right: 50px;
    margin-bottom: 2px;
    justify-content: center;
    gap: 10px;

    }
    .tf-and-c a{
        color: rgb(30, 4, 4);
        text-decoration: none;
    }
.tf-x{
    display: flex;
    justify-content: space-between;
    margin: 30px;
}
.p-ft{
    font-size: 17px;
    font-weight: 500;
    padding-bottom: 25px;
}

.tf-social-icons{
   
        display: flex;
        gap: 10px;
   
   
}
@media (max-width: 1024px) {
    .container {
        max-width: 90%;
    }

    .tf-slider-header h2 {
        font-size: 2em;
    }

    .tf-testimonial-card {
        width: 300px;
    }

    .tf-slide-track {
        width: calc(300px * 7);
    }

    @keyframes scroll {
        0% {
            transform: translateX(0);
        }
        100% {
            transform: translateX(calc(-300px * 7));
        }
    }
   
    .tf-testimonials-button{
        align-items: 100%;
    }
    .tf-faq h2 {
        font-size: 1.8em;

    }
}

@media (max-width: 768px) {
    .tf-slider-header h2 {
        font-size: 1.8em;
    }

    .tf-testimonial-card {
        width: 250px;
        padding: 15px;
    }

    .tf-slide-track {
        width: calc(250px * 7);
    }

    @keyframes scroll {
        0% {
            transform: translateX(0);
        }
        100% {
            transform: translateX(calc(-250px * 7));
        }
    }

    .tf-faq h2 {
        font-size: 1.6em;
    }
}

@media (max-width: 480px) {
    .tf-slider-header h2 {
        font-size: 1.5em;
    }

    .tf-testimonial-card {
        width: 200px;
    }

    .tf-slide-track {
        width: calc(200px * 7);
    }

    .tf-and-c{
        margin-left: 50px;
    }
   

   
    .tf-x{
        display: inline;
    }

    @keyframes scroll {
        0% {
            transform: translateX(0);
        }
        100% {
            transform: translateX(calc(-200px * 7));
        }
    }

    .tf-faq h2 {
        font-size: 1.4em;
    }

    .tf-faq-item button {
        font-size: 1em;
    }
}